import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from '../../config/theme'

const borderRadius = '.2rem'

const Button = styled.button.attrs(({as, bg, fg, inverse, noTransparent, group, active, className}) => ({
  as: as || 'button',
  fg: fg || theme.colors.light.color,
  bg: bg || theme.colors.primary.color,
  inverse: inverse || false,
  noTransparent: noTransparent || false,
  group: group || false,
  className: className + (active ? ' active' : '')
}))`
  color: ${props => props.inverse ? props.bg : props.fg};
  /* color: ${props => props.inverse ? props.bg : props.fg}; */
  background: ${props => props.inverse ? (props.noTransparent ? props.fg : 'transparent') : props.bg};
  border: ${props => props.bg} .1rem solid;
  padding: .5em;

  ${ ({ group }) =>
    group ? `
    &:first-child {
      border-top-left-radius: ${borderRadius};
      border-bottom-left-radius: ${borderRadius};
    }
    &:last-child {
      border-top-right-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }
    &:not(:last-child) {
      border-right: none;
    }
    ` : `
    border-radius: ${borderRadius};
    `
  }

  transition: all 200ms ease-in-out;

  :hover, &.active {
    color: ${props => props.fg};
    /* color: ${props => !props.inverse ? props.bg : props.fg}; */
    background: ${props => !props.inverse ? 'transparent' : props.bg};
  }

  :focus {
    outline: none;
  }

  /* convenience */
  text-decoration: none;

`

Button.propTypes = {
  // as: PropTypes.node,
  fg: PropTypes.string,
  bg: PropTypes.string,
  inverse: PropTypes.bool,
  noTransparent: PropTypes.bool,
  group: PropTypes.bool,
  active: PropTypes.bool,
}

export default Button
