import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Button from '../components/Button';
import theme from '../../config/theme';
import { Link } from 'gatsby';

const NotFound = () =>
  <Layout>
    <Hero>
      <h1>404</h1>
      <h2>Page not found</h2>
      <Button as={Link} to='/' bg={theme.colors.secondary.color}>Go Home</Button>
    </Hero>
  </Layout>

export default NotFound
